var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"lqyouhuiquan"},[_c('img',{attrs:{"src":require("../../assets/yhqme.png"),"alt":""}}),(_vm.type==0)?_c('div',{staticClass:"btn",on:{"click":_vm.fenxiang}},[_vm._v(" 立即助力 ")]):_vm._e(),(_vm.type==1)?_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.login()}}},[_vm._v(" 立即助力 ")]):_vm._e(),_c('div',{staticClass:"zhuli"},[_vm._v(" 好友助力成功可得满9.9减8.9元优惠券 ")])]),_vm._m(3),_c('div',{staticClass:"friend"},[_c('div',{staticClass:"title"},[_vm._v("助力好友")]),_c('p',[_vm._v("邀请好友参加助力！")]),_c('div',{staticClass:"photo"},[_vm._l((_vm.list),function(item){return _c('div',[_c('img',{attrs:{"src":item.member.avatar,"alt":""}}),_c('p',[_vm._v(_vm._s(item.member.nickname))])])}),(this.list=="")?_c('div',{on:{"click":_vm.login}},[_c('img',{attrs:{"src":require("../../assets/lbbr.png"),"alt":""}}),_c('p',[_vm._v("用户名")])]):_vm._e(),(this.list.length<=1)?_c('div',{on:{"click":_vm.login}},[_c('img',{attrs:{"src":require("../../assets/lbyq.png"),"alt":""}}),_c('p',[_vm._v("用户名")])]):_vm._e(),(this.list.length<=2)?_c('div',{on:{"click":_vm.login}},[_c('img',{attrs:{"src":require("../../assets/lbyq.png"),"alt":""}}),_c('p',[_vm._v("邀请更多好友...")])]):_vm._e()],2)]),_vm._m(4),_c('div',{staticClass:"guize",on:{"click":function($event){return _vm.open(0)}}},[_vm._v(" 活动规则 ")]),(this.hidden==true)?_c('div',{staticClass:"guizebox"},[_c('div',{staticClass:"guizetext"},[_c('div',{staticClass:"img",on:{"click":function($event){return _vm.open(1)}}},[_c('img',{attrs:{"src":require("../../assets/lbguanbibtn.png"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v(" 活动规则 ")]),_vm._m(5),_c('div',{staticClass:"titlet"},[_vm._v(" 【注意事项】 ")]),_vm._m(6)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../../assets/lblogo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tishi"},[_c('img',{attrs:{"src":require("../../assets/lbyhqts.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"time"},[_c('p',[_vm._v("活动时间：2022.1.26 10:00:00-2022.3.31 23:59:59")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lqbz"},[_c('div',{staticClass:"tb"},[_c('img',{attrs:{"src":require("../../assets/lbbz1.png"),"alt":""}}),_c('p',[_vm._v("点击我要领取 分享给3个不同的好友")])]),_c('div',{staticClass:"tb"},[_c('img',{attrs:{"src":require("../../assets/lbbz2.png"),"alt":""}}),_c('p',[_vm._v("好友通过你分享的页面 通过注册后点击立即助力")])]),_c('div',{staticClass:"tb"},[_c('img',{attrs:{"src":require("../../assets/lbbz3.png"),"alt":""}}),_c('p',[_vm._v("完成助力任务 即可获得大额优惠券")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('div',{staticClass:"erweima"},[_c('div',[_c('img',{attrs:{"src":require("../../assets/lbxzewm.png"),"alt":""}}),_c('p',[_vm._v("下载考研通APP")])])]),_c('div',{staticClass:"bot"},[_vm._v(" 更多详情可联系考研通客服 "),_c('br'),_vm._v(" 以上最终解释权归考研通所有 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_vm._v(" 1. 参与资格：每一位在考研通APP完成注册的用户都可以参与本次活动；"),_c('br'),_vm._v(" 2. 领取方式：活动期间用户通过直接点击页面中的分享按钮，将活动页面分享至好友邀请其注册；如成功分享3人，即可领取大额主券；"),_c('br'),_vm._v(" 3. 同一个账户、同一个手机号、同一个设备号、同一个支付账号均视为同一用户（适用于您与您的好友） "),_c('br'),_vm._v(" 4. 领取次数：每一位用户至多只能领取主券1张，子券3张；"),_c('br'),_vm._v(" 5.优惠券的发放可能稍有延迟，会将尽快发放到您的账户中; "),_c('br'),_vm._v(" 6.优惠券的数量有限，先到先得；"),_c('br'),_vm._v(" 7.若用户存在刷奖等恶意行为，一经发现将取消抽奖资格（如奖品已经发放，有权追回奖品）；"),_c('br'),_vm._v(" 8.优惠券使用范围：仅可用于指定课程； ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textt"},[_vm._v(" （1）活动过程中，凡以不正当手段（如作弊领取、恶意套取、刷信誉、虚假交易、扰乱系统、实施网络攻击等）参与本次活动的用户，商家有权终止其参与活动，并取消其参与资格（如优惠已发放，商家有权追回），如给商家造成损失的，商家将保留向违规用户继续追索的权利。"),_c('br'),_vm._v(" （2）如遇不可抗力(包括但不限于重大灾害事件、活动受政府机关指令需要停止举办或调整的、活动中存在大面积作弊行为、活动遭受严重网络攻击或因系统故障导致活动中奖名单大批量出错，活动不能正常进行的)，商家有权取消、修改或暂停本活动。 "),_c('br'),_vm._v(" （3）是否获得优惠以活动发布者后台统计结果为准。 ")])
}]

export { render, staticRenderFns }